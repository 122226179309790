
const navBtn = document.querySelector('.nav-btn');
const navMenu = document.querySelector('.nav-menu');


navBtn.addEventListener('click',(e)=>{
e.preventDefault();

navMenu.classList.toggle('d-none');

});


navMenu.querySelectorAll('li').forEach(x=>{
    if(x.querySelector('ul')!==null){
        x.classList.add('parent');
    
        x.querySelector('a').removeAttribute('href');
        x.querySelector('a').style.cursor="pointer";
        
        x.querySelector('ul').classList.add('d-none');

        x.querySelector('a').addEventListener('click',()=>{
            x.querySelector('ul').classList.toggle('d-none');
        })
    
    };
})
 